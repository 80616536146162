<template>
  <div class="content profile-content">
    <ProfileNavbar :title="$t('sidebar.schools-list')"></ProfileNavbar>
    <div class="col-md-12">
      <div class="row">

        <div class="col-lg-4 col-md-12">

          <form-group
              :label="  $t('your_math_teacher') ">

            <el-select @change="setMathsTeacherId" v-model="teachersId.maths_id" :placeholder="$t('your_math_teacher')">
              <el-option
                  v-for="item in teachers_maths"
                  :key="item.id"
                  :label="item.last_name + ' ' + item.first_name"
                  :value="item.id">
              </el-option>
            </el-select>

          </form-group>
          <form-group
              :label="  $t('your_English_teacher') ">

            <el-select @change="setEnglishTeacherId" v-model="teachersId.english_lang_id" :placeholder="$t('your_English_teacher')">
              <el-option
                  v-for="item in teachers_en"
                  :key="item.id"
                  :label="item.last_name + ' ' + item.first_name"
                  :value="item.id">
              </el-option>
            </el-select>

          </form-group>


        </div>
        <div class="col-lg-4 col-md-12">
          <form-group
              :label="  $t('your_science_teacher') ">

            <el-select @change="setNatureTeacherId" v-model="teachersId.natural_science_id" :placeholder="$t('your_science_teacher')">
              <el-option
                  v-for="item in teachers_nature"
                  :key="item.id"
                  :label="item.last_name + ' ' + item.first_name"
                  :value="item.id">
              </el-option>
            </el-select>

          </form-group>
          <form-group
              :label="  $t('your_Kazakh_teacher') ">

            <el-select @change="setKazakhTeacherId" v-model="teachersId.kazakh_lang_id" :placeholder=" $t('your_Kazakh_teacher')">
              <el-option
                  v-for="item in teachers_kk"
                  :key="item.id"
                  :label="item.last_name + ' ' + item.first_name"
                  :value="item.id">
              </el-option>
            </el-select>

          </form-group>

        </div>
        <div class="col-lg-4 col-md-12">
          <form-group
              :label="  $t('your_Russian_teacher') ">

            <el-select @change="setRussianTeacherId" v-model="teachersId.russian_lang_id" :placeholder="$t('your_Russian_teacher')">
              <el-option
                  v-for="item in teachers_ru"
                  :key="item.id"
                  :label="item.last_name + ' ' + item.first_name"
                  :value="item.id">
              </el-option>
            </el-select>

          </form-group>
        </div>
<!--        <div style="margin-left: auto;margin-right: 15px" class="form-group text-center pt-3 btn-controls">-->
<!--          <router-link type="primary" style="margin-left: 0; margin-bottom: 1rem" class="btn-next"-->
<!--                       to="/signup-by-IIn/phone-confirm" tag="el-button">{{ $t('school.back-btn') }}-->
<!--          </router-link>-->
<!--          <el-button style="color: white; background-color: #409EFF" type="button" class="btn-next"-->
<!--                     @click="setTeacherId">{{ $t('signup.next-btn') }}-->
<!--          </el-button>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import ProfileNavbar from '@/components/ProfileNavbar'
import {VueperSlides, VueperSlide} from 'vueperslides'
import VueMask from 'v-mask'
import 'vueperslides/dist/vueperslides.css'
import {required, email} from 'vuelidate/lib/validators'
import Loader from '@/components/Loader'
import * as API from "@/api";

export default {
  name: 'MyTeachers',
  components: {
    ProfileNavbar,
  },
  data() {
    return {
      teachers_maths: [],
      teachers_ru: [],
      teachers_en: [],
      teachers_kk: [],
      teachers_nature: [],
      teachersId: {
        maths_id: '',
        russian_lang_id: '',
        english_lang_id: '',
        kazakh_lang_id: '',
        natural_science_id: '',
      },
    }
  },
  methods: {
    setData() {
      localStorage.setItem('my_teachers', JSON.stringify(['11', '2','5','6','1',]))
    },

    setEnglishTeacherId() {
      this.$http.post(`${API.Api}/api/attach-teacher`, {teacher_id : this.teachersId.english_lang_id})
          .then(res => {
            console.log(res)
            Vue.toastr({
              message: 'Success',
              description: this.$t('teacher_added'),
              type: 'success'
            })
          })
    },

    setMathsTeacherId() {
      this.$http.post(`${API.Api}/api/attach-teacher`, {teacher_id : this.teachersId.maths_id})
          .then(res => {
            console.log(res)
            Vue.toastr({
              message: 'Success',
              description: this.$t('teacher_added'),
              type: 'success'
            })
          })
    },

    setRussianTeacherId() {
      this.$http.post(`${API.Api}/api/attach-teacher`, {teacher_id : this.teachersId.russian_lang_id})
          .then(res => {
            console.log(res)
            Vue.toastr({
              message: 'Success',
              description: this.$t('teacher_added'),
              type: 'success'
            })
          })
    },

    setKazakhTeacherId() {
      this.$http.post(`${API.Api}/api/attach-teacher`, {teacher_id : this.teachersId.kazakh_lang_id})
          .then(res => {
            console.log(res)
            Vue.toastr({
              message: 'Success',
              description: this.$t('teacher_added'),
              type: 'success'
            })
          })
    },

    setNatureTeacherId() {
      this.$http.post(`${API.Api}/api/attach-teacher`, {teacher_id : this.teachersId.natural_science_id})
          .then(res => {
            console.log(res)
            Vue.toastr({
              message: 'Success',
              description: this.$t('teacher_added'),
              type: 'success'
            })
          })
    }

  },
  mounted() {

    //this.setData()

    let userData = JSON.parse(localStorage.getItem('user'))

    let myTeachers = userData.teachers

    let schoolId = userData.education.school.id

    //let schoolId = '7148' // тестовые данные!!!

    this.$http.get(window.API_ROOT + `/api/references/teachers/${schoolId}/2`)
        .then((res) => {
          if (res.body) {
            this.teachers_kk = res.body;


            // for (let i = 0; i < myTeachers.length; i++) {
            //   if (myTeachers[i].subject_id === 12 && myTeachers[i].locality_id === userData.locality_id) {
            //     this.teachersId.kazakh_lang_id = myTeachers[i].id
            //   }
            // }

            for (let i = 0; i < myTeachers.length; i++) {
              if (myTeachers[i].subject_id === 2) {
                this.teachersId.kazakh_lang_id = myTeachers[i].id
              }
            }


            this.regionsLoading = false;
          }
        }).catch((e) => {
      this.regionsLoading = false;
    });

    this.$http.get(window.API_ROOT + `/api/references/teachers/${schoolId}/3`)
        .then((res) => {
          if (res.body) {
            this.teachers_maths = res.body;

            // for (let i = 0; i < myTeachers.length; i++) {
            //   if (myTeachers[i].subject_id === 1 && myTeachers[i].locality_id === userData.locality_id) {
            //     this.teachersId.maths_id = myTeachers[i].id
            //   }
            // }

            for (let i = 0; i < myTeachers.length; i++) {
              if (myTeachers[i].subject_id === 3) {
                this.teachersId.maths_id = myTeachers[i].id
              }
            }

            this.regionsLoading = false;
          }
        }).catch((e) => {
      this.regionsLoading = false;
    });

    this.$http.get(window.API_ROOT + `/api/references/teachers/${schoolId}/5`)
        .then((res) => {
          if (res.body) {
            this.teachers_en = res.body;

            // for (let i = 0; i < myTeachers.length; i++) {
            //   if (myTeachers[i].subject_id === 10 && myTeachers[i].locality_id === userData.locality_id) {
            //     this.teachersId.english_lang_id = myTeachers[i].id
            //   }
            // }

            for (let i = 0; i < myTeachers.length; i++) {
              if (myTeachers[i].subject_id === 5) {
                this.teachersId.english_lang_id = myTeachers[i].id
              }
            }

            this.regionsLoading = false;
          }
        }).catch((e) => {
      this.regionsLoading = false;
    });

    this.$http.get(window.API_ROOT + `/api/references/teachers/${schoolId}/1`)
        .then((res) => {
          if (res.body) {
            this.teachers_ru = res.body;

            // for (let i = 0; i < myTeachers.length; i++) {
            //   if (myTeachers[i].subject_id === 11 && myTeachers[i].locality_id === userData.locality_id) {
            //     this.teachersId.russian_lang_id = myTeachers[i].id
            //   }
            // }

            for (let i = 0; i < myTeachers.length; i++) {
              if (myTeachers[i].subject_id === 1) {
                this.teachersId.russian_lang_id = myTeachers[i].id
              }
            }

            this.regionsLoading = false;
          }
        }).catch((e) => {
      this.regionsLoading = false;
    });

    this.$http.get(window.API_ROOT + `/api/references/teachers/${schoolId}/4`)
        .then((res) => {
          if (res.body) {
            this.teachers_nature = res.body;

            // for (let i = 0; i < myTeachers.length; i++) {
            //   if (myTeachers[i].subject_id === 13 && myTeachers[i].locality_id === userData.locality_id) {
            //     this.teachersId.natural_science_id = myTeachers[i].id
            //   }
            // }

            for (let i = 0; i < myTeachers.length; i++) {
              if (myTeachers[i].subject_id === 4) {
                this.teachersId.natural_science_id = myTeachers[i].id
              }
            }

            this.regionsLoading = false;
          }
        }).catch((e) => {
      this.regionsLoading = false;
    });

    // this.routeSignupHandler()
    // if(sessionStorage.getItem('phone-verified')) {
    //   this.phoneVerified = (sessionStorage.getItem('phone-verified') == 'true');
    // }
    // if(this.phoneVerified!=true){
    //   this.$router.push({ path: '/signup-by-IIn' });
    // }
    // if(sessionStorage.getItem('account')) {
    //   this.account = JSON.parse(sessionStorage.getItem('account'));
    // }
    // if(sessionStorage.getItem('parent')) {
    //   this.parent = JSON.parse(sessionStorage.getItem('parent'));
    //
    // }
    // if(sessionStorage.getItem('education')) {
    //   this.education = JSON.parse(sessionStorage.getItem('education'));
    // }
    // if (this.$store.state.password.savedPassword != 'undefined') {
    //   this.password = this.$store.state.password.savedPassword;
    //   this.password_confirmation = this.$store.state.password.savedPassword;
    // }
  },
}
</script>
<style lang="scss" scoped>
.notification {
  &-title {
    font-size: 24px;
    color: #05458c;
    font-weight: bold;

    img {
      display: inline-block;
      width: 33px;
      height: 33px;
      margin-right: 10px;
      object-fit: contain;
    }
  }

  &-download-wrapper {
    justify-content: center;
    display: flex;
  }

  &-download {
    min-width: 200px;
    margin: 1rem;
    cursor: pointer;
    text-align: center;
    background-color: #2A8BF2;
    height: 48px;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 48px !important;
    text-decoration: none;
    color: white !important;
    border-radius: 10px;
    min-height: 48px;
    display: block;
    max-width: max-content;
  }

  &-date {
    font-size: 18px;
    color: #05458c;
  }

  &-card {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #dfe0eb;
    padding: 33px 26px;
  }

  &-logo {
    display: block;
    width: 147px;
    margin: 0 auto;
  }

  &-fullname {
    font-size: 30px;
    color: #05458C;
    text-align: center;
    margin: 32px 0;
    font-weight: bold;
  }

  &-results {
    color: #18A0FB;

    &__side {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 18px;
        font-weight: bold;
      }
    }

    &__overall {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 30px;
        font-weight: bold;
      }
    }
  }

  &-callcenter {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      padding: 11px;
      border-radius: 10px;
      border: 1px solid #DFE0EB;
      margin-right: 10px;

      &::before {
        content: '';
        display: block;
        float: left;
        width: 22px;
        height: 31px;
        margin-right: 10px;
        background-image: url('/images/phone.svg');
        background-position: center;
        background-size: 90%;
        background-repeat: no-repeat;
      }
    }

    span {
      color: #05458C;
      opacity: 0.3;
    }
  }

  &-copyright {
    text-align: center;
    font-size: 14px;
    color: #05458C;
    opacity: 0.3;
  }
}

@media screen and (max-width: 700px) {
  .notification-results__overall {
    margin: 1rem 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-mobile {
    order: 1;
  }
  .notification {
    &-title {
      font-size: 16px;

      img {
        display: none;
      }
    }

    &-date {
      font-size: 12px;
      margin-left: auto;
    }

    &-card {
      padding: 33px 13px;
    }

    &-results {
      &__side {
        margin: 1rem 0;
        flex: 0 0 100%;
        max-width: 100%;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &-message {
      margin: 15px 0;
    }

    &-callcenter {
      a {
        white-space: nowrap;
        font-size: 14px;
      }

      span {
        font-size: 12px;
      }
    }
  }
}
</style>

